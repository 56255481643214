import { VuesticPlugin } from 'vuestic-ui';
import { createApp } from 'vue'
import { createGtm } from 'vue-gtm'
import { createI18n } from 'vue-i18n'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuesticGlobalConfig from './services/vuestic-ui/global-config'
import axios from 'axios'
import VueAxios from "vue-axios"
import 'nprogress/nprogress.css'
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL || 'https://back.manartours.ma'
axios.defaults.withCredentials = true


const i18nConfig = {
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en: require('@/i18n/en.json'),
    ch: require('@/i18n/cn.json'),
    es: require('@/i18n/es.json'),
    ir: require('@/i18n/ir.json'),
    br: require('@/i18n/br.json')
  }
}

const app = createApp(App)
app.use(store)
app.use(router)
app.use(TawkMessengerVue, {
  propertyId: '62755d5bb0d10b6f3e70f678',
  widgetId: '1g2d79bsn'
});
if (process.env.VUE_APP_GTM_ENABLED === 'true') {
  const gtmConfig = {
    id: process.env.VUE_APP_GTM_KEY,
    debug: false,
    vueRouter: router,
  }
  app.use(createGtm(gtmConfig))
}
app.use(createI18n(i18nConfig))
app.use(VuesticPlugin, vuesticGlobalConfig)
app.use(VueAxios, axios)
app.mount('#app')
