import { createStore } from 'vuex'
import axios from 'axios'

export interface StateInterface {
  isSidebarMinimized: boolean;
  user: User | null;
  permissions: string[];
}

type User = {
  id: number;
  name: string;
  email: string;
  birthdate: string;
  roles: Role[];
}

type Role = {
  id: number;
  name: string;
  permissions: object;
}

export default createStore({
  strict: true, // process.env.NODE_ENV !== 'production',
  state: {
    isSidebarMinimized: false,
    user: null,
    permissions: []
  } as StateInterface,
  mutations: {
    updateSidebarCollapsedState(state, isSidebarMinimized) {
      state.isSidebarMinimized = isSidebarMinimized
    },
    updateUser(state, newUser) {
      state.user = newUser
    },
    updatePermissions(state, newPermissions) {
      state.permissions = newPermissions
    }
  },
  getters: {
    isAuth(state) {
      return !!state.user
    },
    canCreateToursForAllOperators(state) {
      return state.permissions?.includes('create_tours_for_all_operators')
    },
    canCreateGuide(state) {
      return state.permissions?.includes('create_guide_for_all_operators') ||
        state.permissions?.includes('create_guide_for_own_operator')
    },
    canCreateGuideForAllOperators(state) {
      return state.permissions?.includes('create_guide_for_all_operators')
    },
    isSuperAdmin(state) {
      return state.user?.roles?.some((role) => role.name === 'super-admin')
    }
  },
  actions: {
    async getUser({commit}) {
      try {
        const data = await axios.get('/api/guide')
        const user = Object.keys(data.data).length === 0 ? null: data.data
        commit('updateUser', user['guide'])
        commit('updatePermissions', user['permissions'])
      } catch (e: any) {
        commit('updateUser', null)
      }
    },
    async logoutUser({commit}) {
      await axios.post('/api/guide/logout')
      commit('updateUser', null)
    }
  }
})
