import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AuthLayout from '@/layout/auth-layout.vue'
import AppLayout from '@/layout/app-layout.vue'
import Page404Layout from '@/layout/page-404-layout.vue'
import UIRoute from '@/pages/admin/ui/route'
import store from "@/store";
import NProgress from "nprogress";


const routes: Array<RouteRecordRaw> = [
  {
    path: "/:catchAll(.*)",
    redirect: { name: 'dashboard' },
  },
  {
    name: 'admin',
    path: '/admin',
    component: AppLayout,
    meta: {requiresAuth: true},
    children: [
      {
        name: 'dashboard',
        path: 'dashboard',
        component: () => import('@/pages/admin/dashboard/Dashboard.vue'),
      },
      {
        name: 'programs',
        path: 'programs',
        component: () => import('@/pages/admin/program/ProgramList.vue'),
      },
      {
        name: 'Create Program',
        path: 'create_program',
        component: () => import('@/pages/admin/program/ProgramCreate.vue'),
      },
      {
        name: 'Create Program Multi',
        path: 'create_program_bach',
        component: () => import('@/pages/admin/program/ProgramBatchCreate.vue'),
      },
      {
        name: 'Update Program',
        path: 'programs/update/:id',
        component: () => import('@/pages/admin/program/ProgramUpdate.vue'),
      },
      {
        name: 'create_guide',
        path: 'create_guide',
        meta: {permission: 'canCreateGuide'},
        component: () => import('@/pages/admin/guide/GuideCreate.vue'),
      },
      {
        name: 'reservations',
        path: 'reservations',
        component: () => import('@/pages/admin/reservation/ReservationList.vue'),
      },
      {
        name: 'questions',
        path: 'administration/questions',
        component: () => import('@/pages/admin/administration/questions.vue'),
      },
      {
        name: 'statistics',
        path: 'statistics',
        component: () => import('@/pages/admin/statistics/charts/Charts.vue'),
      },
      {
        name: 'settings',
        path: 'settings',
        component: () => import('@/pages/admin/settings/Settings.vue'),
      },
      UIRoute,
    ]
  },
  {
    path: '/auth',
    component: AuthLayout,
    children: [
      {
        name: 'login',
        path: 'login',
        component: () => import('@/pages/auth/login/Login.vue'),
      },
      {
        name: 'signup',
        path: 'signup',
        component: () => import('@/pages/auth/signup/Signup.vue'),
      },
      {
        name: 'recover-password',
        path: 'recover-password',
        component: () => import('@/pages/auth/recover-password/RecoverPassword.vue'),
      },
      {
        path: '',
        redirect: { name: 'login' },
      },
    ],
  },
  {
    path: '/404',
    component: Page404Layout,
    children: [
      {
        name: 'not-found-advanced',
        path: 'not-found-advanced',
        component: () => import('@/pages/404-pages/VaPageNotFoundSearch.vue'),
      },
      {
        name: 'not-found-simple',
        path: 'not-found-simple',
        component: () => import('@/pages/404-pages/VaPageNotFoundSimple.vue'),
      },
      {
        name: 'not-found-custom',
        path: 'not-found-custom',
        component: () => import('@/pages/404-pages/VaPageNotFoundCustom.vue'),
      },
      {
        name: 'not-found-large-text',
        path: '/pages/not-found-large-text',
        component: () => import('@/pages/404-pages/VaPageNotFoundLargeText.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  //  mode: process.env.VUE_APP_ROUTER_MODE_HISTORY === 'true' ? 'history' : 'hash',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name) {
    NProgress.start()
  }
  if (to.meta.requiresAuth) {
    await store.dispatch('getUser')
    if (store.getters.isAuth) {
      if (!to.meta.permission) {
        next()
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        if (to?.meta?.permission === 'canCreateGuide' && store.getters.canCreateGuide) {
          next()
        } else {
          next({name: 'dashboard'})
        }
      }
    } else {
      next('/auth/login')
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})

export default router
