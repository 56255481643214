export default {
  root: {
    name: '/',
    displayName: 'navigationRoutes.home',
  },
  routes: [
    {
      name: 'dashboard',
      displayName: 'menu.dashboard',
      meta: {
        icon: 'vuestic-iconset-dashboard',
      },
    },
    {
      name: 'programs',
      displayName: 'menu.programIndex',
      disabled: true,
      meta: {
        icon: 'vuestic-iconset-files'
      },
      children: [
        {
          name: 'programs',
          displayName: 'menu.programList',
        },
        {
          name: 'Create Program',
          displayName: 'menu.programCreate',
        },
        {
          name: 'Create Program Multi',
          displayName: 'menu.programBatchCreate',
        }
      ]
    },
    {
      name: 'administration',
      displayName: 'menu.administration',
      disabled: true,
      meta: {
        icon: 'vuestic-iconset-dashboard'
      },
      children: [
        {
          name: 'questions',
          displayName: 'menu.administrationQuestions',
        }
      ]
    },
    {
      name: 'create_guide',
      displayName: 'menu.createGuide',
      meta: {
        icon: 'entypo-user-add',
        permission: 'canCreateGuide'
      }
    },
    {
      name: 'reservations',
      displayName: 'menu.reservations',
      meta: {
        icon: 'vuestic-iconset-files'
      },
    }
    // {
    //   name: 'statistics',
    //   displayName: 'menu.statistics',
    //   meta: {
    //     icon: 'vuestic-iconset-statistics',
    //   }
    // }
  ],
}
