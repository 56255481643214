<template>
  <img :src="logoPath()">
</template>
<script>

export default {
  name: "VaIconVuestic",
  props: {
    height: { type: [Number, String], default: 16 },
    color: { type: [String], default: "primary" },
    size: { type: [Number, String], default: 75 },
  },
  methods: {
    logoName() {
      let size = this.size
      if ([16, 32, 50, 75, 100, 200, 250].includes(this.size)) {
        size = this.size
      }
      return `logo_w${size}`
    },
    logoPath() {
      return `/img/logos/${this.logoName()}.png`
    },
  },
};
</script>
